import React, {useState} from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Quotes from "../components/quotes"
import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
// import pencil from "../images/pencils.png"

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import DOMPurify from 'dompurify';

import { getSearchParams } from "gatsby-query-params";

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LinkIcon from '@material-ui/icons/Link';

import Moment from 'react-moment';
import Helmet from "react-helmet"

const APOLLO_QUERY = gql`
  
  query Blogpost($id: Int!) {
 	
 	  postBy(postId: $id) {
	    content
	    featuredImage {
	      node {
	        mediaItemUrl
	      }
	    }
	    author {
	      node {
	        firstName
	        lastName
	      }
	    }
	    title
	    date
	  }
 
  }
`

const shareOnFacebook = () => {

	window.open(
    'https://www.facebook.com/sharer/sharer.php?u='+window.location, 
    'facebook-share-dialog', 
    'width=626,height=436');
}

const shareOnTwitter = () => {

	window.open(
    'https://twitter.com/intent/tweet?url='+window.location, 
    'twitter-share-dialog', 
    'width=626,height=436');
}

const shareOnLinkedIn = () => {

	window.open(
    'https://www.linkedin.com/shareArticle?mini=true&url='+window.location, 
    'linkedin-share-dialog', 
    'width=626,height=436');
}



let content = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;
const sanitizer = DOMPurify.sanitize;

const IndexPage = () => {

	const searchParams = getSearchParams();

	const [linkCopied, setLinkedCopied] = useState(false);

	const share = () => {
		
		let dummy = document.createElement('input'),
	    text = window.location.href;

		document.body.appendChild(dummy);
		dummy.value = text;
		dummy.select();
		document.execCommand('copy');
		document.body.removeChild(dummy);

		setLinkedCopied(true)
	}

	return (
	  <Layout>
	    <SEO title="Blog" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Blog from copywriter The Sharpener</title>
            <meta name="title" content="Blog from copywriter The Sharpener" />
            <meta name="description" content="I’m an experienced Buckinghamshire-based copywriter specialising in blogs, websites and tone of voice. Read my blog to learn more" />
        </Helmet>

	    <Grid container className="main" style={{ display:'flex', alignItems:'flex-start' }}>

	    	 <Query query={APOLLO_QUERY} variables={{ id: searchParams.id }}>

                  {({data}) => {

	                  if(data){

	                  	return(

	                  		<Grid container className="blogpage">
					    		<h1>{sanitizer(data.postBy.title)}</h1>
					    		<Grid container className="work_content_holder">

					    			<Grid item className="blog_text">

					    				<div className="headerImage" style={{ backgroundImage: `url(${data.postBy.featuredImage.node.mediaItemUrl})` }} />
		                  				<h2>{data.postBy.author.node.firstName} {data.postBy.author.node.lastName} - <Moment format="MMMM DD">{data.postBy.date}</Moment></h2>
		                  				<div dangerouslySetInnerHTML={{ __html: sanitizer(data.postBy.content)}} />
		                  				<div className="blog_icons">

		                  					<div onClick={shareOnFacebook} ><FacebookIcon /></div>
		                  					<div onClick={shareOnTwitter} ><TwitterIcon /></div>
		                  					<div onClick={shareOnLinkedIn} ><LinkedInIcon /></div>
		                  					<div onClick={share} ><LinkIcon /></div>
		                  					<div>{linkCopied ? <span className="linkCopied">Link copied!</span> : null}</div>

		                  				</div>

		                  			</Grid>
		                  		</Grid>
	                  		</Grid>


	                  	)
	                   
	                  }

                  return content

                }}

            </Query>

	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
