import React from "react";

import { CircularProgress } from '@material-ui/core';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import useDynamicRefs from 'use-dynamic-refs';
import Grid from '@material-ui/core/Grid';

import DOMPurify from 'dompurify';

const APOLLO_QUERY_QUOTES = gql`
  {

     testimonials {
      nodes {
        TESTIMONIALS {
          date
        }
        content
        title
      }
    }
  

  }
`

let content = <div style={{ padding:'10% 45%' }}><CircularProgress color="inherit" /></div>;

const quoteNum = 4;

const Quotes = (props) => {  

    const sanitizer = DOMPurify.sanitize;

    const [getRefQuote, setRefQuote] =  useDynamicRefs();
    const [getRefButton, setRefButton] =  useDynamicRefs();

    const Buttons = () => {

      return(

        <Grid container className="quoteButtons">

          {Array.from(Array(quoteNum), (e, i) => {
            return <Grid key={i} className="quoteButtonHolder"><Grid className="quoteButton_unselected" ref={setRefButton('quoteButton_'+i)} onClick={() => showQuote(i)} item></Grid></Grid>
          })}

        </Grid>

      )

    }

    const showQuote = (id) => {

      if(getRefQuote('quote_'+0)){

          for(let i=0;i<quoteNum;i++){

              let quote = getRefQuote('quote_'+i);
              if(quote && quote.current){

                quote.current.style.display = 'none';
              }

              let btn = getRefButton('quoteButton_'+i);
              if(btn && btn.current){
                btn.current.style.opacity = 1;
              }

          }

          const quoteChosen = getRefQuote('quote_'+id);
          if(quoteChosen && quoteChosen.current){
            quoteChosen.current.style.display = 'block';
          }
        
          const quoteButton = getRefButton('quoteButton_'+id);
          if(quoteButton && quoteButton.current){
            quoteButton.current.style.opacity = 0.5;
          }

      }
      
    }

    return (

      <React.Fragment>
          
          <Grid container>
        
            <Query query={APOLLO_QUERY_QUOTES} onCompleted={() => showQuote(0)}>

                  {({data}) => {

                    if(data){

                        content = data.testimonials.nodes.map((item, index) => {

                          return (

                            <div className="quotes_box_holder" key={index} ref={setRefQuote('quote_'+index)}>
                              <div className="quotes_box">
                                <div className="client_quote" dangerouslySetInnerHTML={{ __html: sanitizer(item.content) }}></div>
                              </div>
                              <div className="client_quote_info"><div className="client_quote_info_text" dangerouslySetInnerHTML={{ __html: sanitizer(item.title) }}></div></div>
                            </div>

                            )

                        })

                        {/*console.log(data.testimonials.nodes)*/}

                    }

                    return content
                    
                  }}

            </Query>

          </Grid>
           
          <Grid container>

            <Buttons />

          </Grid>

      </React.Fragment>

    )

}

export default Quotes